import { webClient } from "@api/web/WebClient";
import Logo from "@components/Logo";
import Seo from "@components/Seo";
import { withWebLayout } from "@components/web/Decorators";
import DownloadAppButton from "@components/web/DownloadAppButton";
import ItemCardGrid from "@components/web/items/ItemCardGrid";
import ProfilePicture from "@components/web/users/ProfilePicture";
import { useQueryParameter } from "@hooks/navigation";
import { Box, CircularProgress } from "@material-ui/core";
import { Countries, WebUsersGetUserResponse } from "@reshopper/web-client";
import { navigateTo404 } from "@utils/navigation";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";

export default withWebLayout(() => {
    const [userId] = useQueryParameter("id");

    const [user, setUser] = useState<WebUsersGetUserResponse | null>();

    useEffect(() => {
        const fetchUser = async () => {
            if (!userId) {
                setUser(null);
                return;
            }

            const user = await webClient().webUsersGet(userId);
            setUser(user.user ?? null);
        }
        fetchUser();
    }, [])

    if (user === undefined) {
        return <div className={styles.root}>
            <Logo variant="text-white" href="/" />
            <CircularProgress style={{
                color: 'white'
            }} />
        </div>;
    }

    if (user === null) {
        return navigateTo404();
    }

    return <div className={styles.root}>
        <Logo variant="text-white" href="/" />
        <p>
            Se {user.publicName}s shop på Reshopper
        </p>

        <Box textAlign={"center"}>
            <DownloadAppButton text="Hent Reshopper App her" />
        </Box>

        <Seo
            title={user.publicName}
            description={user.bio} />

        <ProfilePicture
            size={90}
            images={user.profileImages}
            wrapperClassName={styles.profilePicture} />

        <header>
            <h1>{user.publicName}</h1>
            {/* <h2>{user.locationInfo?.city}</h2> */}
        </header>

        <main className={styles.main}>
            {user.bio && <p className={styles.bio}>{user.bio}</p>}

            <ItemCardGrid
                className={styles.items}
                maximumItemCount={10000}
                country={user.country as Countries}
                fetchItems={async options =>
                    user && await webClient().webQueriesItemsFacetedPost({
                        body: {
                            ...options,
                            country: user.country?.toUpperCase(),
                            userId: user.id,
                            isSold: false
                        }
                    })}
            />
        </main>
    </div>;
});